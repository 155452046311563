<template>
  <base-modal :loading="isSubmitting" @close="$emit('close')">
    <div class="card-container rounded-3xl p-8">
      <div class="without-scrollbar grid max-h-600 gap-6 overflow-auto">
        <div class="flex items-start justify-between">
          <div class="flex items-start gap-4">
            <div class="pt-1">
              <inline-svg src="img/icons/facebook-logo.svg" class="h-6 w-6" />
            </div>
            <span data-cy="autoshare-fb-header" class="text-2xl font-bold"
              >Auto-Share Tweet to FB</span
            >
          </div>

          <button @click="$emit('close')" class="pt-1">
            <inline-svg src="/img/icons/close.svg" />
          </button>
        </div>

        <new-alert class="my-4" v-if="userProfile.customerStatus === 'none'">
          The auto-share on Facebook feature is one of the many perks of being a Hypefury
          subscriber.<br />
          You can set it up, but it won't work unless you
          <router-link to="/billing">grab a Hypefury subscription</router-link>.
        </new-alert>

        <div class="flex items-center justify-between">
          <span class="app-modal-text mr-2 text-base font-medium text-gray-80">Share as image</span>
          <base-toggle
            name="share-post-as-image"
            v-model="sharePostAsImage"
            id="share-post-as-image"
            data-cy="autoshare-fb-as-images-switch"
          />
        </div>
        <div class="grid gap-6">
          <div ref="textarea_container" v-if="sharePostAsImage">
            <textarea
              rows="3"
              class="form-field"
              ref="textarea"
              maxlength="2000"
              placeholder="Caption and hashtags"
              v-model="facebookText"
              data-cy="autoshare-fb-caption-input"
            >
            </textarea>
          </div>
          <div v-else>
            <p>Your thread will be auto-shared as a regular Facebook textual post.</p>
          </div>

          <div>
            <label
              for="multiple"
              class="dark-mode-text-15 mb-2 block text-base font-medium text-black"
              >Only auto-share if the tweet gets</label
            >
            <div class="grid grid-cols-2 gap-4">
              <base-number-input
                data-cy="autoshare-fb-min-input"
                type="number"
                min="0"
                v-model="minThreshold"
              />
              <base-select
                id="condition"
                v-model="condition"
                placeholder="Select condition"
                labelAttribute="label"
                valueAttribute="value"
                :options="conditionOptions"
                data-cy="autoshare-fb-select"
              />
            </div>
          </div>

          <template v-if="sharePostAsImage">
            <div class="relative mb-2 overflow-hidden rounded-md">
              <div class="overlay" v-if="tweetshotSettings.isLoadingTweetshot">
                <div class="loader">
                  <span class="loading loading-lg"
                    ><inline-svg src="/img/icons/loading.svg"></inline-svg
                  ></span>
                </div>
              </div>
              <img
                :src="tweetshotPreviewURL"
                class="img-thumbnail"
                @error="displayPlaceholderImage"
                @load="tweetshotSettings.isLoadingTweetshot = false"
              />
            </div>

            <div>
              <p class="mb-6 text-lg">Choose a layout</p>
              <div class="grid grid-cols-4 gap-5">
                <button
                  @click="setTweetshotLayout(tweetshotLayout.value)"
                  v-for="tweetshotLayout in tweetshotSettings.tweetshotLayouts"
                  :key="tweetshotLayout.value"
                  class="outline-none focus:outline-none flex cursor-pointer items-center justify-center rounded-lg px-0.5 py-2 md:px-0 md:py-6"
                  :class="[
                    tweetshotSettings.tweetshotLayout == tweetshotLayout.value
                      ? 'border-2 border-main-color-100'
                      : 'border dark-mode-border-60 border-gray-90',
                  ]"
                >
                  <span class="text-xs md:text-lg">{{ tweetshotLayout.name }}</span>
                </button>
              </div>
            </div>
            <div>
              <p class="mb-6 text-lg">Choose a theme</p>
              <div class="md:flex-no-wrap flex flex-wrap gap-x-1 gap-y-2">
                <button
                  v-for="tweetshotTheme in tweetshotSettings.tweetshotThemes"
                  :key="tweetshotTheme.value"
                  @click="setTweetshotTheme(tweetshotTheme.value)"
                  class="transparent flex w-7.5 items-center justify-center rounded-md border-2 border-gray-10"
                  :class="[
                    {
                      'border-2 border-main-color-100':
                        tweetshotSettings.tweetshotTheme == tweetshotTheme.value,
                    },
                  ]"
                >
                  <img
                    :src="`./tweetshot/themes/new-${tweetshotTheme.value}.png`"
                    class="h-auto w-full"
                    alt="square"
                  />
                </button>
              </div>
            </div>
          </template>
        </div>
        <div class="flex items-center justify-end">
          <new-button
            :disabled="!areAutoShareOnFacebookConditionsValid"
            @click="saveSettings"
            data-cy="autoshare-fb-save"
          >
            Save
          </new-button>
        </div>
      </div>
    </div>

    <upgrade-to-premium-pop-up
      :show="showUpgradeToPremiumPopUp"
      :content="upgradeToPremiumPopUpContent"
      :contentForNonSubscribers="upgradeToPremiumPopUpContent"
      @close="showUpgradeToPremiumPopUp = false"
    />
  </base-modal>
</template>

<script>
  import FacebookMixin from '@/views/Mixins/FacebookMixin.vue';
  import TweetshotSettingsMixin from '@/views/Mixins/TweetshotSettingsMixin.vue';
  import lodash from 'lodash';
  import dao from '@/dao';
  import { mapGetters } from 'vuex';
  import UpgradeToPremiumPopUp from '../../components/UpgradeToPremiumPopUp.vue';
  import { adjustTextAreaHeight } from '../../util/adjustTextAreaHeight';
  export default {
    components: { UpgradeToPremiumPopUp },
    created() {
      this.updateValuesFromUserProfile();
      setTimeout(() => {
        if (this.$refs.textarea) {
          adjustTextAreaHeight(this.$refs.textarea, this.$refs.textarea_container);
          this.$refs.textarea.focus();
        }
      }, 0);
    },
    computed: {
      ...mapGetters({ userProfile: 'getUserProfile' }),
      areAutoShareOnFacebookConditionsValid() {
        return !lodash.isNil(this.minThreshold) && Number(this.minThreshold) >= 0;
      },
    },
    data() {
      return {
        isSubmitting: false,
        showUpgradeToPremiumPopUp: false,
        upgradeToPremiumPopUpContent: null,
        conditionOptions: [
          { label: 'Retweets', value: 'retweets' },
          { label: 'Likes', value: 'likes' },
        ],
        condition: 'retweets',
        minThreshold: 5,
        sharePostAsImage: true,
        facebookText: '',
      };
    },
    methods: {
      capitalize: lodash.capitalize,
      displayPlaceholderImage: function (e) {
        e.target.src = '/img/tweetshot_placeholder.jpg';
      },
      updateValuesFromUserProfile() {
        this.resetTweetshotPreview();

        const facebookAutoShare = lodash.get(this, 'userProfile.settings.facebookAutoShare');

        this.minThreshold = facebookAutoShare.minThreshold;
        this.condition = facebookAutoShare.condition;
        this.facebookText = lodash.get(facebookAutoShare, 'facebookText', '');
        this.sharePostAsImage = facebookAutoShare.postType === 'tweetshot';
      },
      async saveSettings() {
        try {
          const postType = this.sharePostAsImage ? 'tweetshot' : 'simple-post';
          const isAutoShareEnabled = lodash.get(
            this,
            'userProfile.settings.facebookAutoShare.enabled',
            false,
          );

          const oldSettings = lodash.get(this, 'userProfile.settings.facebookAutoShare');
          const facebookAutoShare = {
            ...oldSettings,
            enabled: isAutoShareEnabled,
            minThreshold: !isNaN(Number(this.minThreshold)) ? Number(this.minThreshold) : 1,
            condition: this.condition,
            postType,
            ...(postType === 'tweetshot' ? { facebookText: this.facebookText } : {}),
          };

          this.isSubmitting = true;

          const operations = [
            dao.userProfile.updateAutoShareOnFacebookConditions(
              this.userProfile.uid,
              facebookAutoShare,
            ),
          ];

          if (postType === 'tweetshot') {
            operations.push(
              dao.integrations.updateTweetshotSettings(
                this.userProfile.uid,
                this.tweetshotSettings.tweetshotLayout,
                this.tweetshotSettings.tweetshotTheme,
              ),
            );
          }

          await Promise.all(operations);

          if (
            this.userProfile.customerStatus === 'none' &&
            (this.tweetshotSettings.tweetshotLayout !== 'standard' ||
              this.tweetshotSettings.tweetshotTheme !== 'light')
          ) {
            this.upgradeToPremiumPopUpContent =
              `The layout is saved, but it'll only be used if you grab a subscription,` +
              ` otherwise Hypefury will use the default layout.<br/>` +
              `Unlock Insta-shot customization and many other powerful features?`;
            this.showUpgradeToPremiumPopUp = true;
          }

          this.$emit('close');
          this.$notify({ type: 'success', message: 'Auto-share conditions updated!' });
        } catch (error) {
          this.swalModal({
            title: 'Error',
            text: `Sorry, an error has occurred while trying to update the auto-share conditions.`,
            type: 'error',
          });
        } finally {
          this.isSubmitting = false;
        }
      },
    },
    watch: {
      facebookText() {
        setTimeout(() => {
          if (this.$refs.textarea) {
            adjustTextAreaHeight(this.$refs.textarea, this.$refs.textarea_container);
          }
        }, 0);
      },
    },
    mixins: [FacebookMixin, TweetshotSettingsMixin],
  };
</script>

<style scoped>
  .card-container {
    max-width: 604px;
  }
</style>

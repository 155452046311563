<template>
  <div
    class="flex w-full cursor-pointer flex-row items-center"
    :class="source.selected && 'selected'"
    style="min-height: 50px"
    @click="toggleSelection(source)"
  >
    <div class="flex w-1/12 justify-center">
      <new-base-checkbox v-model="source.selected" :value="source.selected" />
    </div>
    <div class="whitespace-no-wrap table-cell w-10 text-left">
      <a target="_blank" :href="`https://twitter.com/${source.username}/status/${source.id}`">
        {{ source.time }}
      </a>
    </div>
    <div :title="source.status" class="table-cell w-1/2 opacity-50" dir="auto">
      <div class="truncate" style="max-width: 99%">{{ source.status }}</div>
    </div>
    <div class="table-cell w-10 text-center opacity-50">{{ source.favoriteCount }}</div>
    <div class="table-cell w-10 text-center opacity-50">
      {{ source.retweetCount }}
    </div>
    <div class="flex w-10 justify-center">
      <base-toggle v-model="source.isFavorite"></base-toggle>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'new-item-component',
    props: {
      index: {
        type: Number,
      },
      source: {
        type: Object,
        default() {
          return {};
        },
      },
    },
    methods: {
      toggleSelection(source) {
        source.selected = !source.selected;
      },
    },
  };
</script>
<style lang="scss" scoped>
  .w-10 {
    width: 10%;
  }

  .selected {
    @apply bg-divider;
    box-shadow: 0 1px 0 var(--color-divider);
  }
</style>

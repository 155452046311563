<template>
  <base-modal @close="$emit('close')">
    <div class="card-container rounded-3xl p-8">
      <div class="grid gap-6">
        <div class="flex items-center justify-between">
          <div class="flex items-center gap-2">
            <inline-svg class="h-6 w-6" src="img/icons/retweet-icon.svg" />
            <span class="text-2xl font-bold">Auto Retweeter</span>
          </div>
          <button @click="$emit('close')">
            <inline-svg src="/img/icons/close.svg" />
          </button>
        </div>
        <p class="text-lg">
          Use this tool to automatically retweet another Twitter account (e.g. company account) when
          it tweets through Hypefury.
        </p>

        <new-alert class="my-4" v-if="isUserPlanBasicOrBelow(userProfile.customerStatus)">
          The Auto-Retweeter is one of the many perks of being a Hypefury
          {{ getPlanLabel('standard') }} subscriber.<br />
          You can set it up, but it won't work unless you
          <router-link to="/billing"
            >grab a {{ getPlanLabel('standard') }} Hypefury subscription</router-link
          >.
        </new-alert>

        <div class="container-fluid mt-2 space-y-6">
          <div data-cy="retweeting" v-if="usernames.length > 0">
            You are currently retweeting
            <span v-for="(username, index) in usernames" :key="`username-${index}`">
              <span>@{{ username }}</span>
              <template v-if="index !== usernames.length - 1">
                <template v-if="index === usernames.length - 2"> and </template>
                <template v-else>, </template>
              </template></span
            >.<br /><br />
            Every tweet they post
            <i>through Hypefury</i> will be retweeted by your account @{{ userProfile.username }}.
          </div>

          <div data-cy="is-updating" v-else-if="isUpdating">
            Updating data...
          </div>

          <div data-cy="not-retweeting" v-else>
            You are currently not retweeting any account. Add one below.
          </div>

          <div class="flex flex-col items-end gap-x-2 gap-y-4 md:flex-row md:items-center">
            <base-text-input
              data-cy="retweeter-input"
              placeholder="Twitter handle"
              v-model.trim="username"
              maxlength="15"
              @keyup.enter="
                !isUpdating && canAddAccountsToRetweet ? addUserToRetweet() : null
              "
            />
            <div class="flex flex-shrink-0 items-center gap-x-2">
              <new-button
                @click="addUserToRetweet"
                :disabled="isUpdating || !canAddAccountsToRetweet"
                data-cy="add-user-button"
              >
                Add user
              </new-button>
              <new-button
                data-cy="save-to-retweet"
                @click="saveUsersToRetweet"
                :disabled="isUpdating || !canSaveAccountsToRetweet"
              >
                Save
              </new-button>
            </div>
          </div>

          <div v-if="selectedUsersToShow.length > 0" class="flex flex-wrap gap-2">
            <div
              data-cy="selected-user"
              v-for="(user, i) in selectedUsersToShow"
              :key="`selected-user-${i}`"
              class="relative"
            >
              <tooltip :content="`Stop retweeting ${user.username}`">
                <button
                  data-cy="stop-retweeting"
                  class="absolute right-0 top-0 z-10"
                  @click="removeUser(user)"
                >
                  <inline-svg src="/img/icons/close.svg" class="mr-1 mt-1 h-3 w-3" />
                </button>
              </tooltip>
              <new-button v-if="user.action !== 'remove'" @click="removeUser(user)">
                <span>@{{ user.username }}</span>
              </new-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </base-modal>
</template>

<script>
  import { mapGetters } from 'vuex';
  import controller from '@/controller';
  import dao from '@/dao';
  import SwalModalMixin from '../Mixins/SwalModalMixin.vue';
  import { AxiosError } from 'axios';
  import CustomerStatusMixin from '../Mixins/CustomerStatusMixin.vue';

  const USER_ID_WITH_SPECIAL_GRANT = 't1Bo1NKK8XYlS9emkPsXfV3ATUk2';

  export default {
    name: 'auto-retweeter-modal',
    mixins: [SwalModalMixin, CustomerStatusMixin],
    computed: {
      ...mapGetters({ userProfile: 'getUserProfile', currentUser: 'getCurrentUser' }),
      selectedUsersToShow() {
        return this.selectedUsers.filter((user) => user.action !== 'remove');
      },
      limitPerPlan() {
        if (this.userProfile.uid === USER_ID_WITH_SPECIAL_GRANT) {
          return 10;
        } else {
          return 3;
        }
      },
      canSaveAccountsToRetweet() {
        if (this.selectedUsers.length !== this.usernames.length) {
          return true;
        }
        return !this.selectedUsers.every((u) => {
          if (u.action && u.action === 'remove') {
            return false;
          }
          return Boolean(this.accountsToRetweet[u.username]);
        });
      },
      usernames() {
        return Object.values(this.accountsToRetweet).map((u) => u.username);
      },
      canAddAccountsToRetweet() {
        return Boolean(this.username) && this.selectedUsers.length < this.limitPerPlan;
      },
    },
    data() {
      return {
        selectedUsers: [],
        username: '',
        accountsToRetweet: {},
        isUpdating: false,
      };
    },
    methods: {
      removeUser: function (user) {
        const userExistsInDB = Boolean(this.accountsToRetweet[user.username]);
        if (userExistsInDB) {
          this.selectedUsers = this.selectedUsers.map((u) => {
            if (u.username === user.username) {
              return { ...u, action: 'remove' };
            }
            return u;
          });
        } else {
          this.selectedUsers = this.selectedUsers.filter((u) => u.username !== user.username);
        }
      },
      updateAccountsToRetweet: async function (idsToRemove) {
        const updatedAccountsToBeRetweeted = this.userProfile.accountsToBeRetweeted.filter(
          (id) => !idsToRemove.includes(id)
        );
        await dao.userProfile.updateAccountsToRetweet(
          this.userProfile.uid,
          updatedAccountsToBeRetweeted
        );
        this.$eventStore.removeAnAccountToRetweet();
      },
      saveUsersToRetweet: async function () {
        this.isUpdating = true;
        try {
          const [usersToBeRemoved, usersToAdd] = this.selectedUsers.reduce(
            (acc, user) => {
              if (user.action === 'remove') {
                acc[0].push(this.accountsToRetweet[user.username].id);
              } else if (Boolean(this.accountsToRetweet[user.username]) === false) {
                acc[1].push(user.username);
              }
              return acc;
            },
            [[], []]
          );
          if (usersToBeRemoved.length > 0) {
            await this.updateAccountsToRetweet(usersToBeRemoved);
          }
          if (usersToAdd.length > 0) {
            const { userIds } = await controller.selectUsersToRetweet(
              this.currentUser,
              this.userProfile,
              usersToAdd
            );
            this.selectedUsers = this.selectedUsers.map((user, i) => {
              return {
                ...user,
                id: userIds[i],
              };
            });
          }
          const message = `Auto retweeter settings saved.`;
          this.$eventStore.addAnAccountToRetweet();
          this.$notify({ type: 'success', message });
        } catch (error) {
          let message = 'An error occurred while saving Auto retweeter settings.';
          if (error instanceof AxiosError && error.response.status === 400) {
            let userNotFound = '';
            error.response.data.usernames.forEach((username) => {
              userNotFound += `<li>${username}</li>`;
            });
            message = `The following users were not found: <ul>${userNotFound}</ul>`;
          }
          this.swalModal({
            title: 'Error',
            html: message,
            type: 'warning',
          });
        } finally {
          this.isUpdating = false;
          this.username = '';
          this.selectedUsers = this.selectedUsers.filter((u) => u.action !== 'remove');
          this.accountsToRetweet = {};
          this.selectedUsers.forEach((user) => {
            this.accountsToRetweet[user.username] = user;
          });
        }
      },
      addUserToRetweet() {
        this.selectedUsers.push({
          username: this.username,
        });
        this.username = '';
      },
    },
    async created() {
      const nonExistentAccounts = [];
      const handleError = (error, twitterId) => {
        if ([403, 404].includes(error.response.status)) {
          nonExistentAccounts.push(twitterId);
        }
      };

      if (this.userProfile.accountsToBeRetweeted.length > 0) {
        const promises = this.userProfile.accountsToBeRetweeted.map((twitterId) => {
          return controller
            .getTwitterUserInfo(this.currentUser, this.userProfile, twitterId)
            .catch((error) => {
              handleError(error, twitterId);
            });
        });

        this.isUpdating = true;

        const usersInfo = (await Promise.all(promises)).filter((result) => Boolean(result));
        this.accountsToRetweet = usersInfo.reduce((acc, userInfo) => {
          acc = {
            ...acc,
            [userInfo.username]: {
              id: userInfo.id,
              username: userInfo.username,
            },
          };
          return acc;
        }, {});
        this.selectedUsers = [...Object.values(this.accountsToRetweet)];

        this.isUpdating = false;

        if (nonExistentAccounts.length) {
          return this.swalModal({
            title: 'Error',
            html: 'Some accounts that you saved before do not exist anymore or are suspended by Twitter.',
          }).then((result) => {
            const updatedAccountsToBeRetweeted = this.userProfile.accountsToBeRetweeted.filter(
              (id) => !nonExistentAccounts.includes(id)
            );
            return dao.userProfile.updateAccountsToRetweet(
              this.userProfile.uid,
              updatedAccountsToBeRetweeted
            );
          });
        }
      }
    },
  };
</script>

<style scoped>
  .card-container {
    max-width: 466px;
  }
</style>

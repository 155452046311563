<template>
  <div class="space-y-3">
    <div v-if="isSocialMediaRelated">
      <div class="flex items-center gap-6">
        <div
          class="flex items-center justify-center rounded-full bg-ds-button-secondary-background p-3"
        >
          <inline-svg class="h-6 w-6 fill-blues-50 text-blues-50" :src="socialMediaIcon" />
        </div>
        <p class="text-2xl font-bold">{{ socialMediaTitle }}</p>
      </div>
    </div>
    <div class="card space-y-6">
      <div class="flex items-center gap-3">
        <h3>{{ title }}</h3>
        <inline-svg class="h-5 w-5" :src="icon" />
      </div>
      <p class="text-base dark-mode:text-dark-mode-15">
        {{ description }}
      </p>
      <div class="flex items-center justify-between">
        <new-button @click="$emit('openSettings')" type="light" :data-cy="settingsCypressSelector">
          {{ settingsLabel }}
        </new-button>
        <base-toggle
          v-if="showToggle"
          size="lg"
          :data-cy="toggleCypressSelector"
          @input="$emit('toggle', $event)"
          :value="enabled"
        />
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'PowerupsCard',
    props: {
      tag: {
        type: String,
        default: 'Powerup',
      },
      title: {
        type: String,
        default: 'Powerups Tiltle',
      },
      socialMediaTitle: {
        type: String,
        default: 'Powerups Tiltle',
      },
      icon: {
        type: String,
        required: true,
      },
      socialMediaIcon: {
        type: String,
        default: 'Powerups Tiltle',
      },
      description: {
        type: String,
        default: 'Powerups Description',
      },
      showToggle: {
        type: Boolean,
        default: false,
      },
      enabled: {
        type: Boolean,
        default: false,
      },
      settingsLabel: {
        type: String,
        default: 'Settings',
      },
      toggleCypressSelector: {
        type: String,
        default: '',
      },
      settingsCypressSelector: {
        type: String,
        default: '',
      },
      isSocialMediaRelated: {
        type: Boolean,
        default: false,
      },
    },
  };
</script>

<style lang="scss" scoped>
  body {
    &.new-ui {
      .card {
        @apply block rounded-2xl p-6;
        .tag {
          @apply rounded-md px-3 py-2 text-sm font-medium uppercase;
        }
        h3 {
          @apply text-lg font-bold;
        }
      }
      &.mode--light {
        .card {
          @apply border border-gray-60 bg-white;
          .tag {
            @apply bg-gray-10 text-black;
          }
        }
      }
      &.mode--dark {
        .card {
          @apply border-0 bg-dark-mode-70;
          .tag {
            @apply bg-dark-mode-60 text-dark-mode-15;
          }
        }
      }
    }
  }
</style>

export const tweetshotOptions = {
  layouts: [
    // { name: 'Random', value: 'random' },
    { name: 'Standard', value: 'standard', includesMedia: true },
    { name: 'Bubble I', value: 'bubble1' },
    { name: 'Bubble II', value: 'bubble2' },
    { name: 'Bubble III', value: 'bubble3' },
    { name: 'Bubble IV', value: 'bubble4' },
    { name: 'Bubble V', value: 'bubble5' },
    { name: 'Bubble VI', value: 'bubble6' },
    { name: 'Fab I', value: 'fab1' },
    { name: 'Fab II', value: 'fab2' },
    { name: 'Iconic I', value: 'iconic1' },
    { name: 'Nova I', value: 'nova1' },
    { name: 'Nova II', value: 'nova2' },
    { name: 'Nova III', value: 'nova3' },
    { name: 'Nova IV', value: 'nova4' },
    { name: 'Quote I', value: 'quote1' },
    { name: 'Simple I', value: 'simple1' },
    { name: 'Simple II', value: 'simple2' },
    { name: 'Square I', value: 'square1' },
    { name: 'Square II', value: 'square2' },
  ],
  themes: [
    // { name: 'Random', value: 'random' },
    { name: 'Light', value: 'light' },
    { name: 'Dark', value: 'dark' },
    { name: 'Lights Out', value: 'lights_out' },
    { name: 'Autumn', value: 'autumn' },
    { name: 'Carnation', value: 'carnation' },
    { name: 'Cerise', value: 'cerise' },
    { name: 'Forest', value: 'forest' },
    { name: 'Growth', value: 'growth' },
    { name: 'Pattens Blue', value: 'pattens_blue' },
    { name: 'Moon Yellow', value: 'moon_yellow' },
    { name: 'Pistachio', value: 'pistachio' },
    { name: 'Pumpkin', value: 'pumpkin' },
    { name: 'Sapphire', value: 'sapphire' },
    { name: 'Shamrock', value: 'shamrock' },
    { name: 'Spanish Yellow', value: 'spanish_yellow' },
  ],
};

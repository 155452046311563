<template>
  <base-modal v-if="show" @close="close" :loading="submitting">
    <div class="card-container rounded-3xl p-8">
      <div class="grid gap-6">
        <div class="flex items-center justify-between">
          <div class="flex items-center gap-2">
            <inline-svg class="h-6 w-6 text-twitter-100" src="img/icons/new/unretweet.svg" />
            <span data-cy="unretweet-header" class="text-2xl font-bold">Auto Unretweeter</span>
          </div>
          <button data-cy="unretweet-close" @click="close">
            <inline-svg src="/img/icons/close.svg" />
          </button>
        </div>
        <p data-cy="unretweet-description" class="text-lg">
          Unretweet all your tweets from the past 24h, on a daily basis, keeping your timeline
          clean.
        </p>

        <new-alert class="my-4" v-if="userProfile.customerStatus === 'none'">
          The Auto-Unretweeter is one of the many perks of being a Hypefury subscriber.<br />
          You can set it up, but it won't work unless you
          <router-link to="/billing">grab a Hypefury subscription</router-link>.
        </new-alert>

        <div class="grid gap-6">
          <span class="block text-base font-medium text-dark-mode-15">
            Apply to selected accounts:
          </span>
          <div class="row">
            <div class="flex items-center justify-between">
              <div class="flex items-center">
                <p class="mr-1 text-base">Unretweet your tweets</p>
              </div>
              <base-toggle
                name="unretweet-your-tweets"
                id="unretweet-your-tweets"
                data-cy="unretweet-your-tweets-switch"
                v-model="unretweet"
              />
            </div>
          </div>

          <div v-if="unretweet" class="row">
            <div class="flex items-center justify-between">
              <div class="flex items-center">
                <p class="mr-1 text-base">Unretweet retweets from other accounts</p>
              </div>
              <base-toggle
                data-cy="unretweet-other-tweets-switch"
                name="unretweet-other-people-tweets"
                id="unretweet-other-people-tweets"
                v-model="unretweetOtherPeopleTweets"
              />
            </div>
          </div>
        </div>
        <div class="flex items-center justify-end">
          <new-button data-cy="unretweet-save" @click="saveAutoUnretweeterSettings">
            Save
          </new-button>
        </div>
      </div>
    </div>
  </base-modal>
</template>

<script>
  import { mapGetters } from 'vuex';
  import SwalModalMixin from '../Mixins/SwalModalMixin';
  import lodash from 'lodash';
  import dao from '@/dao';
  import CustomerStatusMixin from '../Mixins/CustomerStatusMixin.vue';

  export default {
    props: {
      show: Boolean,
    },
    created() {
      this.onModalVisible();
    },
    data() {
      return this.initialValue();
    },
    computed: {
      ...mapGetters({ currentUser: 'getCurrentUser', userProfile: 'getUserProfile' }),
    },
    methods: {
      close() {
        Object.assign(this.$data, this.initialValue());
        this.$emit('close');
      },
      initialValue() {
        return {
          submitting: false,
          unretweet: false,
          unretweetOtherPeopleTweets: true,
        };
      },
      async saveAutoUnretweeterSettings() {
        try {
          this.submitting = true;

          await dao.userProfile.updateAutoUnretweeterSettings(this.userProfile.uid, {
            enabled: this.unretweet,
            unretweetOtherPeopleTweets: this.unretweetOtherPeopleTweets,
          });

          this.$notify({
            type: 'success',
            message: 'Unretweet settings updated successfully!',
          });

          this.close();
        } catch {
          this.swalModal({
            title: 'Sorry',
            text: `An error has occurred while updating the Auto-Unretweeter.`,
            type: 'warning',
          });
        } finally {
          this.submitting = false;
        }
      },
      updateValuesFromUserProfile() {
        const autoUnretweeterSettings = lodash.get(this, 'userProfile.settings.autoUnretweeterSettings');

        if (!autoUnretweeterSettings) {
          return;
        }

        this.unretweet = autoUnretweeterSettings.enabled;
        this.unretweetOtherPeopleTweets = autoUnretweeterSettings.unretweetOtherPeopleTweets;
      },
      onModalVisible(show = true) {
        if (show) {
          this.updateValuesFromUserProfile();
        }
      },
    },
    watch: {
      show(val) {
        this.onModalVisible(val);
      },
    },
    mixins: [SwalModalMixin, CustomerStatusMixin],
  };
</script>

<style scoped>
  .card-container {
    max-width: 466px;
  }
</style>

<template>
  <base-modal @close="close" :loading="submitting">
    <div class="card-container rounded-3xl p-8">
      <div class="grid gap-6">
        <div class="flex items-center justify-between">
          <div class="flex items-center gap-2">
            <div>
              <inline-svg class="h-6 w-6" src="/img/icons/autoplugs-icon.svg" />
            </div>
            <span data-cy="linkedin-autoplug-header" class="text-2xl font-bold"
              >LinkedIn Autoplug</span
            >
          </div>
          <button data-cy="linkedin-autoplug-close" @click="$emit('close')">
            <inline-svg src="/img/icons/close.svg" />
          </button>
        </div>
        <new-alert v-if="isUserPlanStandardOrBelow(userProfile.customerStatus)">
          The LinkedIn autoplugs feature is one of the many perks of being a Hypefury subscriber.<br />
          You can set it up, but it won't work unless you
          <upgrade-to-premium-link>upgrade to {{ getPlanLabel('premium') }}</upgrade-to-premium-link
          >.
        </new-alert>
        <p data-cy="linkedin-autoplug-description" class="text-lg">
          Auto reply to your own posts to share links or promos in the comments.
        </p>
        <new-alert v-if="!isUserConnectedToLinkedIn">
          <p>
            The LinkedIn autoplugs feature won't work unless you
            <router-link to="/settings#linkedin" class="text-main-color-100"
              >authenticate with your LinkedIn account</router-link
            >.
          </p>
        </new-alert>
        <div>
          <label
            for="multiple"
            class="dark-mode-text-15 mb-2 block text-base font-medium text-black"
            >What would you like to say?</label
          >
          <div class="relative" style="margin-bottom: -4px">
            <textarea
              v-model="text"
              ref="textarea"
              class="form-field"
              placeholder="Mention your mailing list, your ecommerce store, or anything else."
              rows="3"
              data-cy="linkedin-autoplug-textarea"
            />
            <span class="absolute bottom-0 right-0 pb-4 pr-4">
              <character-counter
                :textLength="text.length"
                :warningCharacters="50"
                :maximumCharacters="1250"
              />
            </span>
          </div>
        </div>
        <div>
          <label
            for="multiple"
            class="dark-mode-text-15 mb-2 block text-base font-medium text-black"
            >Only autoplug if the tweet gets</label
          >
          <div class="flex items-center space-x-10">
            <base-number-input
              type="number"
              min="0"
              v-model="minThreshold"
              placeholder="5"
              value="5"
              data-cy="linkedin-autoplug-min-likes"
            />
            <span class="text-base">Likes</span>
          </div>
        </div>
        <div class="flex items-center justify-end">
          <new-button
            :disabled="!areLinkedInAutoplugConditionsValid"
            @click="saveLinkedInAutoplugConditions"
            data-cy="linkedin-autoplug-save"
          >
            Save
          </new-button>
        </div>
      </div>
    </div>
  </base-modal>
</template>

<script>
  import { mapGetters } from 'vuex';
  import SwalModalMixin from '../Mixins/SwalModalMixin';
  import dao from '@/dao';
  import lodash from 'lodash';
  import LinkedInMixin from '../Mixins/LinkedInMixin.vue';
  import CharacterCounter from '../../components/CharacterCounter.vue';
  import CustomerStatusMixin from '../Mixins/CustomerStatusMixin.vue';

  export default {
    props: {
      show: Boolean,
    },
    components: { CharacterCounter },
    created() {
      this.onModalVisible();
    },
    data() {
      return this.initialValue();
    },
    computed: {
      ...mapGetters({ currentUser: 'getCurrentUser', userProfile: 'getUserProfile' }),
      areLinkedInAutoplugConditionsValid() {
        return (
          this.text &&
          this.text.length > 0 &&
          this.text.length <= 1250 &&
          !lodash.isNil(this.minThreshold) &&
          Number(this.minThreshold) >= 0
        );
      },
      getCharactersLeftCount() {
        return 1250 - this.text.length;
      },
      getCharactersLeftClass() {
        const statusLength = this.text.length;
        if (statusLength >= 1250) return 'character-count-exceeded';
        if (statusLength > 1200) return 'character-count-warning';
        return 'strokeColor';
      },
    },
    methods: {
      close() {
        Object.assign(this.$data, this.initialValue());
        this.$emit('close');
      },
      initialValue() {
        return {
          submitting: false,
          isLinkedInAutoplugEnabled: false,
          minThreshold: 5,
          text: '',
        };
      },
      async saveLinkedInAutoplugConditions() {
        try {
          const linkedInAutoplugConditions = {
            ...lodash.get(this.userProfile, 'settings.linkedInAutoplug', {}),
            minThreshold: !isNaN(Number(this.minThreshold)) ? Number(this.minThreshold) : 5,
            text: this.text,
          };

          this.submitting = true;

          await dao.userProfile.updateLinkedInAutoplugConditions(
            this.userProfile.uid,
            linkedInAutoplugConditions
          );

          this.close();
          this.$notify({ type: 'success', message: 'LinkedIn autoplug conditions updated!' });
        } catch {
          this.swalModal({
            title: 'Error',
            text: `Sorry, an error has occurred while trying to update the LinkedIn autoplug conditions.`,
            type: 'error',
          });
        } finally {
          this.submitting = false;
        }
      },
      updateValuesFromUserProfile() {
        const linkedInAutoplug = lodash.get(this, 'userProfile.settings.linkedInAutoplug');

        if (!linkedInAutoplug) {
          return;
        }

        this.isLinkedInAutoplugEnabled = lodash.get(linkedInAutoplug, 'enabled', false);
        this.minThreshold = lodash.get(linkedInAutoplug, 'minThreshold', 5);
        this.text = lodash.get(linkedInAutoplug, 'text', '');
      },
      onModalVisible(show = true) {
        if (show) {
          this.updateValuesFromUserProfile();

          if (this.$refs.textarea) {
            this.$nextTick(() => {
              this.$refs.textarea.focus();
            });
          }
        }
      },
    },
    watch: {
      show(val) {
        this.onModalVisible(val);
      },
      isLinkedInAutoplugEnabled(val) {
        if (val) {
          this.$nextTick(() => {
            this.$refs.textarea.focus();
          });
        }
      },
      $route(val) {
        // If the user navigates to the settings page, we want to close the modal
        if (val && val.meta.id === 'settings') {
          this.close();
        }
      },
    },
    mixins: [SwalModalMixin, LinkedInMixin, CustomerStatusMixin],
  };
</script>

<style scoped>
  .card-container {
    max-width: 604px;
  }
</style>

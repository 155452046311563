<template>
  <div data-cy="powerups-page" class="flex flex-col items-center p-8 pt-18">
    <div class="container w-full space-y-6">
      <div class="flex items-center gap-6">
        <div
          class="flex items-center justify-center rounded-full bg-ds-button-secondary-background p-3"
        >
          <inline-svg class="h-6 w-6 text-blues-50" src="/img/icons/x.svg" />
        </div>
        <h1 class="">Twitter / X</h1>
      </div>
      <new-alert
        v-if="userProfile.customerStatus === 'none'"
        class="mb-8 flex items-center justify-between"
      >
        <div class="flex items-center gap-4" data-cy="powerups-subscription-alert">
          <inline-svg src="img/icons/diamond-icon.svg" />
          <span class="text-base font-medium leading-none text-yellow-100 sm:text-lg"
            >Get a subscription to turn these features on</span
          >
        </div>
        <router-link
          data-cy="powerups-upgrade-link"
          to="/billing"
          class="button small-button flex-shrink-0 bg-yellow-100 text-white"
          >See Plans</router-link
        >
      </new-alert>

      <div class="grid w-full grid-cols-1 gap-8 md:grid-cols-2">
        <powerups-card
          v-for="(powerup, idx) in powerups"
          :key="`powerups-card-${idx}`"
          v-bind="powerup"
          @toggle="powerup.showToggle ? powerup.onToggle($event) : null"
          @openSettings="powerup.onOpenSettings"
        />
      </div>

      <conditional-retweets-modal
        v-if="isTweetBoosterModalVisible"
        :show="isTweetBoosterModalVisible"
        @close="isTweetBoosterModalVisible = false"
      />

      <auto-retweeter-modal
        v-if="isAutoRetweeterModalVisible"
        @close="isAutoRetweeterModalVisible = false"
      />

      <autoplug-modal :show="isAutoplugModalVisible" @close="closeAutoplugModal" />

      <linked-in-autoplug-modal
        v-if="isLinkedInAutoplugModalVisible"
        :show="isLinkedInAutoplugModalVisible"
        @close="closeLinkedInAutoplugModal"
      />

      <new-auto-share-on-instagram-modal
        v-if="isAutoShareOnInstagramModalVisible"
        @close="isAutoShareOnInstagramModalVisible = false"
      />

      <new-auto-share-on-facebook-modal
        v-if="isAutoShareOnFacebookModalVisible"
        @close="isAutoShareOnFacebookModalVisible = false"
      />

      <auto-unretweeter-modal
        v-if="isAutoUnretweeterModalVisible"
        :show="isAutoUnretweeterModalVisible"
        @close="isAutoUnretweeterModalVisible = false"
      />

      <archive-uploader-modal
        v-if="isArchiveUploaderModalVisible"
        :show="isArchiveUploaderModalVisible"
        @close="isArchiveUploaderModalVisible = false"
      />

      <UploadCSVModal
        v-if="isUploadCSVModalVisible"
        :show="isUploadCSVModalVisible"
        @close="isUploadCSVModalVisible = false"
      />
    </div>
  </div>
</template>

<script>
  import lodash from 'lodash';
  import { mapGetters } from 'vuex';
  import UploadCSVModal from '@/views/Modals/UploadCSVModal.vue';
  import ConditionalRetweetsModal from '@/views/Modals/ConditionalRetweetsModal.vue';
  import AutoplugModal from '@/views/Modals/AutoplugModal.vue';
  import LinkedInAutoplugModal from '@/views/Modals/LinkedInAutoplugModal.vue';
  import AutoUnretweeterModal from '@/views/Modals/AutoUnretweeterModal.vue';
  import NewAutoShareOnInstagramModal from '@/views/Modals/NewAutoShareOnInstagramModal.vue';
  import NewAutoShareOnFacebookModal from '@/views/Modals/NewAutoShareOnFacebookModal.vue';
  import AutoRetweeterModal from '@/views/Modals/AutoRetweeterModal.vue';
  import ArchiveUploaderModal from '@/views/Modals/ArchiveUploaderModal.vue';
  import GetHypefurySubscriptionMixin from '@/views/Mixins/GetHypefurySubscriptionMixin.vue';
  import dao from '@/dao';
  import PowerupsCard from '@/components/Cards/PowerupsCard.vue';

  export default {
    name: 'Powerups',
    components: {
      UploadCSVModal,
      ConditionalRetweetsModal,
      LinkedInAutoplugModal,
      AutoplugModal,
      NewAutoShareOnInstagramModal,
      NewAutoShareOnFacebookModal,
      AutoUnretweeterModal,
      AutoRetweeterModal,
      PowerupsCard,
      ArchiveUploaderModal,
    },
    computed: {
      ...mapGetters({ userProfile: 'getUserProfile' }),
      isTweetBoosterEnabled: {
        get() {
          return lodash.get(
            this.userProfile,
            'settings.conditionalRetweetsConditions.conditionalRetweetsEnabled',
            false,
          );
        },
        async set() {
          // If the user has never set the auto retweeter settings, we set the default settings
          if (!lodash.get(this.userProfile, 'settings.conditionalRetweetsConditions')) {
            const DEFAULT_AUTO_RETWEETER_SETTINGS = {
              conditionalRetweetsEnabled: true,
              delayForRetweet: '12 hours',
              minFavoritesThreshold: 5,
              minRetweetsThreshold: null,
            };

            await dao.userProfile.saveGlobalConditionalRetweetsConditionsToFirestore(
              this.userProfile.uid,
              DEFAULT_AUTO_RETWEETER_SETTINGS,
            );
          } else {
            await dao.userProfile.toggleConditionalRetweets(
              this.userProfile.uid,
              !this.isTweetBoosterEnabled,
            );
          }
          this.$notify({
            type: 'success',
            message: 'Tweet Booster settings updated successfully!',
          });
        },
      },
      isAutoplugEnabled: {
        get() {
          return lodash.get(this.userProfile, 'settings.autoplugConditions.autoplugEnabled', false);
        },
        async set() {
          // If the user has never set the autoplug settings, we open the settings modal for them.
          if (!lodash.get(this.userProfile, 'settings.autoplugConditions.status')) {
            this.isAutoplugModalVisible = true;
          }

          await dao.userProfile.toggleAutoplug(this.userProfile.uid, !this.isAutoplugEnabled);
          this.$notify({ type: 'success', message: 'Autoplug settings updated successfully!' });
        },
      },
      isLinkedInAutoplugEnabled: {
        get() {
          return lodash.get(this.userProfile, 'settings.linkedInAutoplug.enabled', false);
        },
        async set() {
          // If the user has never set the LinkedIn autoplug settings, we open the settings modal for them.
          if (!lodash.get(this.userProfile, 'settings.linkedInAutoplug.text')) {
            this.isLinkedInAutoplugModalVisible = true;
          }

          await dao.userProfile.toggleLinkedInAutoplug(
            this.userProfile.uid,
            !this.isLinkedInAutoplugEnabled,
          );
          this.$notify({
            type: 'success',
            message: 'LinkedIn Autoplug settings updated successfully!',
          });
        },
      },
      isAutoShareOnInstagramEnabled: {
        get() {
          return lodash.get(this.userProfile, 'settings.instagramAutoShare.enabled', false);
        },
        async set() {
          // If the user has never set the auto-share on instagram settings, we set the default settings
          if (!lodash.get(this.userProfile, 'settings.instagramAutoShare')) {
            const DEFAULT_AUTO_SHARE_ON_INSTAGRAM_SETTINGS = {
              enabled: true,
              lastTimeFeatureWasEnabled: new Date(),
              minThreshold: 5,
              condition: 'retweets',
              instagramCaption:
                '#getthingsdone #winnercity #seizetheday #doer #theshit #successandnothingless #winorlose #tweets #relatabletweets #tweetsfromtwitter #tweetsavages #tweetstagram #mindsetshift #mindsetcreator #boldanddetermined #mindsetofgreatness #mindsets #mindsetiskey #mindsetchange #mindsetmastery #mindsetofexcellence #mindsetofachampion #money💰 #moneymoneymoney #lifeadvice #goodadvice #adviceforlife',
            };

            await dao.userProfile.updateAutoShareOnInstagramConditions(
              this.userProfile.uid,
              DEFAULT_AUTO_SHARE_ON_INSTAGRAM_SETTINGS,
            );
          } else {
            await dao.userProfile.toggleAutoShareOnInstagram(
              this.userProfile.uid,
              !this.isAutoShareOnInstagramEnabled,
            );
          }

          this.$notify({
            type: 'success',
            message: 'Auto-share on instagram settings updated successfully!',
          });
        },
      },
      isAutoShareOnFacebookEnabled: {
        get() {
          return lodash.get(this.userProfile, 'settings.facebookAutoShare.enabled', false);
        },
        async set() {
          // If the user has never set the auto-share on facebook settings, we set the default settings
          if (!lodash.get(this.userProfile, 'settings.facebookAutoShare')) {
            const DEFAULT_AUTO_SHARE_ON_FACEBOOK_SETTINGS = {
              enabled: true,
              lastTimeFeatureWasEnabled: new Date(),
              minThreshold: 5,
              condition: 'retweets',
              facebookText: '',
            };

            await dao.userProfile.updateAutoShareOnFacebookConditions(
              this.userProfile.uid,
              DEFAULT_AUTO_SHARE_ON_FACEBOOK_SETTINGS,
            );
          } else {
            await dao.userProfile.toggleAutoShareOnFacebook(
              this.userProfile.uid,
              !this.isAutoShareOnFacebookEnabled,
            );
          }

          this.$notify({
            type: 'success',
            message: 'Auto-share on Facebook settings updated successfully!',
          });
        },
      },
      powerups() {
        const that = this;
        return [
          {
            title: 'Tweet Booster',
            icon: 'img/icons/retweet-icon.svg',
            description: 'Automatically retweet your own tweets to give them more visibility.',
            settingsCypressSelector: 'powerups-tweet-booster-settings',
            toggleCypressSelector: 'powerups-tweet-booster-switch',
            onOpenSettings: that.showTweetBoosterModal,
            showToggle: true,
            enabled: that.isTweetBoosterEnabled,
            onToggle: (payload) => {
              this.$set(this, 'isTweetBoosterEnabled', payload);
              if (payload) {
                this.$eventStore.powerups('Tweet Booster: Enabled');
              } else {
                this.$eventStore.powerups('Tweet Booster: Disabled');
              }
            },
          },
          {
            title: 'Autoplug',
            icon: 'img/icons/autoplugs-icon.svg',
            description: 'Auto reply to your own tweets to share links or promos in the comments.',
            settingsCypressSelector: 'powerups-twitter-autoplug-settings',
            onOpenSettings: that.showAutoPlugsModal,
            enabled: that.isAutoplugEnabled,
            toggleCypressSelector: 'powerups-twitter-autoplug-switch',
            onToggle: (payload) => {
              this.$set(this, 'isAutoplugEnabled', payload);
              if (payload) {
                this.$eventStore.powerups('Autoplug: Enabled');
              } else {
                this.$eventStore.powerups('Autoplug: Disabled');
              }
            },
            showToggle: true,
          },
          {
            title: 'Auto Retweeter',
            icon: 'img/icons/retweet-icon.svg',
            description:
              'Automatically retweet another Twitter account (e.g. company account) when it tweets through Hypefury.',
            settingsCypressSelector: 'powerups-auto-retweeter-settings',
            onOpenSettings: that.showAutoRetweetModal,
          },
          {
            title: 'Auto Unretweeter',
            icon: 'img/icons/new/unretweet.svg',
            description: 'Every 24h, unretweet all the tweets you retweeted in the last 24h.',
            settingsCypressSelector: 'powerups-auto-unretweeter-settings',
            onOpenSettings: that.showAutoUnretweeterModal,
          },
          {
            title: 'Upload CSV',
            tag: 'POWERUP TOOL',
            icon: '/img/icons/new/upload-doc.svg',
            description:
              'Batch upload tweets to your queue. Each row will be added to the next slot.',
            settingsCypressSelector: 'powerups-upload-csv-upload',
            onOpenSettings: that.showUploadCSVModal,
            settingsLabel: 'Upload',
          },
          {
            title: 'Archive Uploader',
            tag: 'POWERUP TOOL',
            icon: 'img/icons/new/upload-doc.svg',
            description:
              'Upload the archived tweets from your tweets.js file. Retweets and replies will not be included.',
            settingsCypressSelector: 'powerups-archive-uploader-upload',
            onOpenSettings: that.showArchiveUploaderModal,
            settingsLabel: 'Upload',
          },
          {
            title: 'Auto-Share Tweet to FB',
            socialMediaTitle: 'Facebook',
            icon: 'img/icons/new/siren.svg',
            socialMediaIcon: 'img/icons/facebook-icon.svg',
            description: 'Automatically cross-post your tweets to FB after condition is met.',
            settingsCypressSelector: 'powerups-post-tweet-to-fb-settings',
            onOpenSettings: that.showAutoShareOnFacebookModal,
            enabled: that.isAutoShareOnFacebookEnabled,
            toggleCypressSelector: 'powerups-post-tweet-to-fb-switch',
            onToggle: (payload) => {
              this.$set(this, 'isAutoShareOnFacebookEnabled', payload);
              if (payload) {
                this.$eventStore.powerups('Auto-Share Tweet to FB: Enabled');
              } else {
                this.$eventStore.powerups('Auto-Share Tweet to FB: Disabled');
              }
            },
            showToggle: true,
            isSocialMediaRelated: true,
          },
          {
            title: 'Auto-Share Tweet to IG',
            socialMediaTitle: 'Instagram',
            icon: 'img/icons/new/siren.svg',
            socialMediaIcon: 'img/icons/instagram-icon.svg',
            description: 'Automatically cross-post your tweets to IG to expand your audience.',
            settingsCypressSelector: 'powerups-post-tweet-to-ig-settings',
            onOpenSettings: that.showAutoShareOnInstagramModal,
            enabled: that.isAutoShareOnInstagramEnabled,
            toggleCypressSelector: 'powerups-post-tweet-to-ig-switch',
            onToggle: (payload) => {
              this.$set(this, 'isAutoShareOnInstagramEnabled', payload);
              if (payload) {
                this.$eventStore.powerups('Auto-Share Tweet to IG: Enabled');
              } else {
                this.$eventStore.powerups('Auto-Share Tweet to IG: Disabled');
              }
            },
            showToggle: true,
            isSocialMediaRelated: true,
          },
          {
            title: 'Autoplug',
            socialMediaTitle: 'Linkedin',
            icon: 'img/icons/autoplugs-icon.svg',
            socialMediaIcon: 'img/icons/linkedin-icon.svg',
            description: 'Auto reply to your own posts to share links or promos in the comments.',
            settingsCypressSelector: 'powerups-linkedin-autoplug-settings',
            onOpenSettings: that.showLinkedInAutoplugModal,
            enabled: that.isLinkedInAutoplugEnabled,
            toggleCypressSelector: 'powerups-linkedin-autoplug-switch',
            onToggle: (payload) => {
              this.$set(this, 'isLinkedInAutoplugEnabled', payload);
              if (payload) {
                this.$eventStore.powerups('LinkedIn Autoplug: Enabled');
              } else {
                this.$eventStore.powerups('LinkedIn Autoplug: Disabled');
              }
            },
            showToggle: true,
            isSocialMediaRelated: true,
          },
        ];
      },
    },
    mounted() {
      if (this.$route.hash === '#autoplug') {
        this.isAutoplugModalVisible = true;
      } else if (this.$route.hash === '#auto-retweeter') {
        this.isTweetBoosterModalVisible = true;
      }
    },
    data() {
      return {
        isTweetBoosterModalVisible: false,
        isAutoplugModalVisible: false,
        isLinkedInAutoplugModalVisible: false,
        isAutoShareOnInstagramModalVisible: false,
        isAutoShareOnFacebookModalVisible: false,
        isUploadCSVModalVisible: false,
        isAutoUnretweeterModalVisible: false,
        isAutoRetweeterModalVisible: false,
        isArchiveUploaderModalVisible: false,
      };
    },
    methods: {
      async closeAutoplugModal() {
        this.isAutoplugModalVisible = false;

        // If the user has never set the autoplug settings, and they close the modal, we disable it
        if (!lodash.get(this.userProfile, 'settings.autoplugConditions.status')) {
          await dao.userProfile.toggleAutoplug(this.userProfile.uid, false);
        }
      },
      async closeLinkedInAutoplugModal() {
        this.isLinkedInAutoplugModalVisible = false;

        // If the user has never set the LinkedIn autoplug settings, and they close the modal, we disable it
        if (!lodash.get(this.userProfile, 'settings.linkedInAutoplug.text')) {
          await dao.userProfile.toggleLinkedInAutoplug(this.userProfile.uid, false);
        }
      },
      showUploadCSVModal() {
        if (this.userProfile.customerStatus === 'none') {
          this.displayGetHypefurySubscriptionPopUp(
            'Upload a CSV file',
            'queue tweets from a CSV file',
          );
        } else {
          this.isUploadCSVModalVisible = true;
          this.$eventStore.powerups('Upload CSV: Upload');
        }
      },
      showTweetBoosterModal() {
        this.isTweetBoosterModalVisible = true;
        this.$eventStore.powerups('Tweet Booster: Settings');

      },
      showAutoUnretweeterModal() {
        this.isAutoUnretweeterModalVisible = true;
        this.$eventStore.powerups('Auto Unretweeter: Settings');
      },
      showAutoPlugsModal() {
        this.isAutoplugModalVisible = true;
        this.$eventStore.powerups('Autoplug: Settings');
      },
      showLinkedInAutoplugModal() {
        this.isLinkedInAutoplugModalVisible = true;
        this.$eventStore.powerups('LinkedIn Autoplug: Settings');
      },
      showAutoShareOnInstagramModal() {
        this.isAutoShareOnInstagramModalVisible = true;
        this.$eventStore.powerups('Auto-Share Tweet to IG: Settings');
      },
      showAutoShareOnFacebookModal() {
        this.isAutoShareOnFacebookModalVisible = true;
        this.$eventStore.powerups('Auto-Share Tweet to FB: Settings');
      },
      showAutoRetweetModal() {
        this.isAutoRetweeterModalVisible = true;
        this.$eventStore.powerups('Auto Retweeter: Settings');
      },
      showArchiveUploaderModal() {
        this.isArchiveUploaderModalVisible = true;
        this.$eventStore.powerups('Archive Uploader: Upload');
      },
    },
    mixins: [GetHypefurySubscriptionMixin],
  };
</script>

<style scoped>
  .container {
    max-width: 952px;
  }
</style>

<template>
  <base-modal v-if="show" @close="close" :loading="submitting">
    <div class="card-container space-y-2 rounded-3xl p-8">
      <div class="grid gap-6">
        <div class="flex items-center justify-between">
          <div class="flex items-center gap-2">
            <div>
              <inline-svg class="h-6 w-6 text-twitter-100" src="img/icons/retweet-icon.svg" />
            </div>
            <span data-cy="retweet-header" class="text-2xl font-bold">Tweet Booster</span>
          </div>
          <button data-cy="retweet-close" @click="$emit('close')">
            <inline-svg src="/img/icons/close.svg" />
          </button>
        </div>
        <p data-cy="retweet-description" class="text-lg">
          Automatically retweet your own tweets to give them more visibility.
        </p>
        <new-alert class="my-2" v-if="userProfile.customerStatus === 'none'">
          The booster is one of the many perks of being a Hypefury subscriber.
          <br />
          You can set it up, but it won't work unless you
          <router-link to="/billing">grab a Hypefury subscription</router-link>.
        </new-alert>

        <div>
          <label for="delay" class="mb-2 block text-base font-medium text-dark-mode-15"
            >Delay
          </label>
          <base-select
            v-model="delayForRetweet"
            :options="delayForRetweetOptions"
            placeholder="Select delay"
            data-cy="retweet-delay"
            data-toggle="select"
          />
        </div>
      </div>
      <div>
        <label class="mb-2 block text-base font-medium text-dark-mode-15"
          >Only retweet if the tweet gets</label
        >
        <div class="grid grid-cols-2 gap-4">
          <base-number-input
            data-cy="retweet-min-retweets"
            v-if="conditionForRetweet === 'Retweets'"
            min="0"
            v-model="minRetweetsThreshold"
            name="minRetweetsThreshold"
          />
          <base-number-input
            v-else
            data-cy="retweet-min-likes"
            min="0"
            v-model="minFavoritesThreshold"
            name="minFavoritesThreshold"
          />
          <base-select
            v-model="conditionForRetweet"
            :options="conditionForRetweetOptions"
            placeholder="Select delay"
            data-cy="retweet-condition-select"
            data-toggle="select"
          />
        </div>
      </div>
      <div class="flex items-center justify-end">
        <new-button
          class="mt-4"
          data-cy="retweet-save"
          size="md"
          :disabled="!areConditionalRetweetsConditionsValid"
          @click="saveConditionalRetweetsConditions"
        >
          Save
        </new-button>
      </div>
    </div>
  </base-modal>
</template>
<script>
  import lodash from 'lodash';
  import { mapGetters } from 'vuex';
  import dao from '@/dao';
  import SwalModalMixin from '../Mixins/SwalModalMixin';
  import CustomerStatusMixin from '../Mixins/CustomerStatusMixin.vue';

  export default {
    name: 'conditional-retweets-modal',
    props: {
      show: Boolean,
      isFirstTimeUser: {
        type: Boolean,
        default: false,
      },
    },
    created() {
      this.onModalVisible();
    },
    watch: {
      show(val) {
        this.onModalVisible(val);
      },
      minFavoritesThreshold(newVal) {
        //Ensure that the value is at least 1 without modifying any preexisting values
        if (newVal <= 0 && this.isMinFavoritesThresholdDirty) {
          this.minFavoritesThreshold = 1;
        }
      },
      minRetweetsThreshold(newVal) {
        //Ensure that the value is at least 1 without modifying any preexisting values
        if (newVal <= 0 && this.isMinRetweetsThresholdDirty) {
          this.minRetweetsThreshold = 1;
        }
      },
    },
    data() {
      return {
        minFavoritesThreshold: 5,
        isMinFavoritesThresholdDirty: false,
        minRetweetsThreshold: 5,
        isMinRetweetsThresholdDirty: false,
        conditionalRetweetsEnabled: false,
        delayForRetweet: '12 hours',
        delayForRetweetOptions: [
          '30 minutes',
          '1 hour',
          '3 hours',
          '6 hours',
          '9 hours',
          '12 hours',
          '18 hours',
          '24 hours',
          '36 hours',
          '48 hours',
        ],
        hasModalJustOpened: false,
        submitting: false,
        conditionForRetweet: 'Likes',
        conditionForRetweetOptions: ['Retweets', 'Likes'],
        originalMinRetweets: null,
      };
    },
    computed: {
      ...mapGetters({ currentUser: 'getCurrentUser', userProfile: 'getUserProfile' }),
      areConditionalRetweetsConditionsValid() {
        // For the new UI, conditionalRetweetsEnabled will be set by the toggler so we always assume that it's enabled
        const areMinFavoritesConditionsValid =
          this.conditionForRetweet === 'Likes' &&
          !lodash.isNil(this.minFavoritesThreshold) &&
          Number(this.minFavoritesThreshold) >= 1;
        const areMinRetweetsConditionsValid =
          this.conditionForRetweet === 'Retweets' &&
          !lodash.isNil(this.minRetweetsThreshold) &&
          Number(this.minRetweetsThreshold) >= 1;

        return areMinFavoritesConditionsValid || areMinRetweetsConditionsValid;
      },
    },
    methods: {
      close() {
        this.$emit('close');
      },
      async saveConditionalRetweetsConditions() {
        const conditionalRetweetsConditions = {
          // For the new UI, conditionalRetweetsEnabled will be set by the toggler so we won't save it here
          ...lodash.get(this.userProfile, 'settings.conditionalRetweetsConditions', {}),
          delayForRetweet: this.delayForRetweet ? this.delayForRetweet : '1 hour',
          minFavoritesThreshold: this.conditionForRetweet === 'Likes' ? Number(this.minFavoritesThreshold) : null,
          minRetweetsThreshold: this.conditionForRetweet === 'Retweets' ? Number(this.minRetweetsThreshold) : null,
        };

        this.submitting = true;

        try {
          await dao.userProfile.saveGlobalConditionalRetweetsConditionsToFirestore(
            this.userProfile.uid,
            conditionalRetweetsConditions
          );
          this.$notify({ type: 'success', message: 'Boost conditions updated!' });
        } catch (error) {
          this.swalModal({
            title: 'Error',
            text: `Sorry, an error has occurred while trying to update the boost conditions.`,
            type: 'error',
          });
        }
        this.submitting = false;
        this.close();
      },
      updateValuesFromUserProfile() {
        const oldConditionalRetweetsConditions = lodash.get(
          this,
          'userProfile.conditionalRetweetsConditions'
        );
        const newConditionalRetweetsConditions = lodash.get(
          this,
          'userProfile.settings.conditionalRetweetsConditions'
        );

        if (!newConditionalRetweetsConditions && !oldConditionalRetweetsConditions) {
          return;
        }

        const conditionalRetweetsConditionsToUse = newConditionalRetweetsConditions
          ? newConditionalRetweetsConditions
          : oldConditionalRetweetsConditions;

        const delayForRetweet = conditionalRetweetsConditionsToUse.delayForRetweet;
        if (delayForRetweet) {
          this.delayForRetweet = delayForRetweet;
        }

        this.originalMinRetweets = conditionalRetweetsConditionsToUse.minRetweetsThreshold;

        this.conditionalRetweetsEnabled = conditionalRetweetsConditionsToUse.conditionalRetweetsEnabled;
        this.minRetweetsThreshold = conditionalRetweetsConditionsToUse.minRetweetsThreshold;
        this.minFavoritesThreshold = conditionalRetweetsConditionsToUse.minFavoritesThreshold;
      },
      resetConditionalRetweetsMinLikesIfEmpty() {
        if (!this.minFavoritesThreshold && this.minFavoritesThreshold !== 0) {
          this.minFavoritesThreshold = 5;
        }
        if (!this.minRetweetsThreshold && this.minRetweetsThreshold !== 0) {
          this.minRetweetsThreshold = 5;
        }
      },
      onModalVisible(show = true) {
        if (show) {
          if (this.isFirstTimeUser) {
            this.conditionalRetweetsEnabled = true;
            this.conditionForRetweet = 'Likes';
            this.minFavoritesThreshold = 10;
            return;
          }
          this.updateValuesFromUserProfile();
          this.resetConditionalRetweetsMinLikesIfEmpty();

          if (!lodash.isNil(this.originalMinRetweets)) {
            this.conditionForRetweet = 'Retweets';
          } else {
            this.conditionForRetweet = 'Likes';
          }
        }
      },
    },
    mixins: [SwalModalMixin, CustomerStatusMixin],
  };
</script>

<style scoped>
  .save-conditional-retweet {
    text-align: center !important;
  }

  .card-container {
    max-width: 466px;
  }
</style>

<script>
  import lodash from 'lodash';
  import { mapGetters } from 'vuex';
  import { tweetshotOptions } from '@/data/tweetshot-options.js';
  const config = require('@/config');

  export default {
    data() {
      return {
        tweetshotSettings: {
          tweetshotLayout: null,
          tweetshotLayouts: tweetshotOptions.layouts,
          tweetshotTheme: null,
          tweetshotThemes: tweetshotOptions.themes,
          canSaveTweetshotSettings: false,
          isLoadingTweetshot: false,
          isSavingTweetshotSettings: false,
        },
      };
    },
    computed: {
      ...mapGetters({ userProfile: 'getUserProfile' }),
      userTweetshotSettings() {
        const tweetshotSettings = lodash.get(this.userProfile, 'settings.tweetshot');
        return tweetshotSettings || {};
      },
      tweetshotPreviewURL() {
        if (this.isRandomTweetshotThemeOrLayoutSelected) {
          return '/img/tweetshot_placeholder.jpg';
        }
        return config.buildStorageMediaURL(
          `tweetshot-previews/${this.tweetshotSettings.tweetshotLayout}&${this.tweetshotSettings.tweetshotTheme}.png`
        );
      },
      isRandomTweetshotThemeOrLayoutSelected() {
        return (
          this.tweetshotSettings.tweetshotLayout === 'random' ||
          this.tweetshotSettings.tweetshotTheme === 'random'
        );
      },
    },
    methods: {
      resetTweetshotPreview: function () {
        this.tweetshotSettings.canSaveTweetshotSettings = false;
        this.tweetshotSettings.isLoadingTweetshot = false;
        this.tweetshotSettings.tweetshotLayout = lodash.get(
          this.userTweetshotSettings,
          'layout',
          'standard'
        );
        this.tweetshotSettings.tweetshotTheme = lodash.get(
          this.userTweetshotSettings,
          'theme',
          'light'
        );
      },
      selectRandomTweetshotSettings: function () {
        const randomLayout = lodash.sample(this.tweetshotSettings.tweetshotLayouts).value;
        const randomTheme = lodash.sample(this.tweetshotSettings.tweetshotThemes).value;
        this.setTweetshotLayout(randomLayout);
        this.setTweetshotTheme(randomTheme);
      },
      setTweetshotLayout: function (tweetshotLayout) {
        if (this.tweetshotSettings.tweetshotLayout !== tweetshotLayout) {
          this.tweetshotSettings.tweetshotLayout = tweetshotLayout;
          this.tweetshotSettings.canSaveTweetshotSettings = true;
          if (!this.isRandomTweetshotThemeOrLayoutSelected) {
            this.tweetshotSettings.isLoadingTweetshot = true;
          }
        }
      },
      setTweetshotTheme: function (tweetshotTheme) {
        if (this.tweetshotSettings.tweetshotTheme !== tweetshotTheme) {
          this.tweetshotSettings.tweetshotTheme = tweetshotTheme;
          this.tweetshotSettings.canSaveTweetshotSettings = true;
          if (!this.isRandomTweetshotThemeOrLayoutSelected) {
            this.tweetshotSettings.isLoadingTweetshot = true;
          }
        }
      },
    },
  };
</script>

